import { AccountMeta, PublicKey, Transaction, TransactionSignature } from '@solana/web3.js';
import { encode } from 'bs58';
import { parseBigInt } from './strings';
import { NeonEmulate, SolanaAccount } from '@neonevm/token-transfer-core';
import { Web3Account } from 'web3-eth-accounts';
import { Web3 } from 'web3';
import { Transaction as TransactionConfig } from 'web3-types';
import { TransactionReceipt } from 'viem';


export function solanaTransactionLog(transaction: Transaction): string {
  return transaction.instructions.map(({ programId, keys, data }, index) => {
    const result = [];
    result.push(`[${index}] programId: ${programId.toBase58()}`);
    result.push(`keys: ${keys.map(k => `${k.pubkey.toBase58()} [${k.isSigner ? 'signer' : ''}${k.isSigner && k.isWritable ? ', ' : ''}${k.isWritable ? 'writer' : ''}]`).join('\n')}`);
    result.push(`data: ${encode(data)}\n`, `0x${Buffer.from(data).toString('hex')}`);
    return result.join('\n');
  }).join(`\n\n`);
}

export function solanaLog(transaction?: Transaction, signature?: TransactionSignature): string {
  const result: string[] = [];
  if (transaction) {
    result.push(solanaTransactionLog(transaction));
  }
  if (signature) {
    result.push(`Signature: ${signature}`);
  }
  return result.join('\n');
}

export function neonLog(transaction?: TransactionConfig, signature?: TransactionReceipt): string {
  const result: string[] = [];
  if (transaction) {
    result.push(`Transaction: ${JSON.stringify(transaction, parseBigInt)}`);
  }
  if (signature) {
    result.push(`Signature: ${signature.blockHash}`);
  }
  return result.join('\n');
}


export function createClaimInstructionSync(neonEmulate: NeonEmulate): {
  neonKeys: AccountMeta[];
  legacyAccounts: SolanaAccount[];
} {
  const legacyAccounts: SolanaAccount[] = [];
  const accountsMap = new Map<string, AccountMeta>();
  if (neonEmulate!) {
    const { accounts = [], solanaAccounts = [] } = neonEmulate;
    for (const account of accounts) {
      const key = account['account'];
      accountsMap.set(key, { pubkey: new PublicKey(key), isSigner: false, isWritable: true });
      if (account['contract']) {
        const key = account['contract'];
        accountsMap.set(key, { pubkey: new PublicKey(key), isSigner: false, isWritable: true });
      }
    }

    for (const account of solanaAccounts) {
      const { pubkey, isLegacy, isWritable } = account;
      accountsMap.set(pubkey, {
        pubkey: new PublicKey(pubkey),
        isSigner: false,
        isWritable
      });
      if (isLegacy) {
        legacyAccounts.push(account);
      }
    }
  }
  return { neonKeys: Array.from(accountsMap.values()), legacyAccounts };
}

export function solanaWalletSigner(web3: Web3, pk: string): Web3Account {
  return web3.eth.accounts.privateKeyToAccount(pk);
}
