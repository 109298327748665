import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  finalize,
  from,
  Observable,
  of,
  ReplaySubject,
  Subject,
  SubscriptionLike,
  switchMap,
  tap
} from 'rxjs';
import { delay } from 'rxjs/operators';
import { itemUnsubscribe } from '../../../utils';
import { PendingStatus, TokenTransferStatus, TransferTokenFormData } from '../../../models';
import {
  NeonChainService,
  ProxyStatusService,
  PythService,
  SentryLogService,
  SolanaWalletService,
  TokensListService,
  TokenTransferService,
  WalletConnectService
} from '../../../token-transfer/services';
import { VisibilityService } from '../../services';
import { TokenTransferFormComponent } from '../../../token-transfer/components';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
  status$ = new BehaviorSubject<TokenTransferStatus>(TokenTransferStatus.form);
  loading: ReplaySubject<boolean> = new ReplaySubject<boolean>(0);
  resetForm: Subject<boolean> = new Subject<boolean>();
  private sub: SubscriptionLike[] = [];
  private name = 'app';

  @ViewChild(TokenTransferFormComponent) formComponent: TokenTransferFormComponent;

  tryTokenTransfer = (data: TransferTokenFormData): void => {
    this.sub.push(this.transfer.transfer(data).pipe(
      switchMap(result => from(this.transfer.confirmTransaction(result))),
      tap(this.transferComplete),
      catchError(this.transactionRejected),
      delay(3e2),
      switchMap(() => this.tokens.tokenBalance(data.token)),
      finalize(() => this.loading.next(false))).subscribe());
  };

  tokenTransfer(data: TransferTokenFormData): void {
    this.loading.next(true);
    this.transfer.formData$.next(data);
    this.tryTokenTransfer(data);
    this.status$.next(TokenTransferStatus.transfer);
  }

  backToForm(): void {
    this.status$.next(TokenTransferStatus.form);
  }

  private transferComplete = () => {
    this.loading.next(false);
    this.resetForm.next(true);
    this.transfer.pendingStatus$.next(PendingStatus.confirmed);
  };

  private transactionRejected = (error: unknown): Observable<unknown> => {
    this.loading.next(false);
    this.transfer.transferLog('error', error);
    this.transfer.pendingStatus$.next(PendingStatus.rejected);
    return of(null);
  };

  private show = () => {
    this.neon.refresh();
    this.solana.refresh();
  };

  private hidden = () => {
  };

  ngOnInit(): void {
    this.proxy.init();
    this.tokens.init();
    this.transfer.init();
    this.chain.init();
    this.sn.init();
    this.solana.init();
    this.neon.init();
    this.pyth.init();
    this.v.init(this.name, this.show, this.hidden);
    (window as any).gas = this.ga;
  }

  ngOnDestroy(): void {
    this.proxy.destroy();
    this.tokens.destroy();
    this.transfer.destroy();
    this.chain.destroy();
    this.sn.destroy();
    this.solana.destroy();
    this.neon.destroy();
    this.pyth.destroy();
    this.loading.complete();
    this.resetForm.complete();
    itemUnsubscribe(this.sub);
  }

  constructor(public transfer: TokenTransferService, private proxy: ProxyStatusService, private tokens: TokensListService,
              private neon: WalletConnectService, private solana: SolanaWalletService, private chain: NeonChainService,
              private v: VisibilityService, private sn: SentryLogService, private pyth: PythService, private ga: GoogleAnalyticsService) {
  }
}
