import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { TransactionExpiredBlockheightExceededError } from '@solana/web3.js';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  first,
  firstValueFrom,
  map,
  mergeMap,
  Observable,
  ReplaySubject,
  Subject,
  SubscriptionLike,
  tap
} from 'rxjs';
import { filter, throttleTime } from 'rxjs/operators';
import { Big } from 'big.js';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import {
  ERROR_MESSAGE,
  FormValidators,
  fractionLength,
  itemUnsubscribe,
  NEON,
  priorityFeeLamports,
  SOL,
  toAmountView,
  W_NEON
} from '../../../utils';
import {
  PendingStatus,
  TokenTransferStatus,
  TransferDirection,
  TransferToken,
  TransferTokenFee,
  TransferTokenFormData
} from '../../../models';
import {
  NeonChainService,
  NeonTransferFeeService,
  PriorityFeeService,
  PythService,
  SolanaWalletService,
  TokensListService,
  TokenTransferFeeService,
  TokenTransferService,
  TransferTransactionService,
  WalletConnectService
} from '../../services';
import { collapseAnimation } from '../../../shared/animations';
import { environment } from '../../../environments/environment';
import { TokenAmountComponent } from '../token-amount/token-amount.component';
import { NEON_TOKEN_MINT_DECIMALS } from '@neonevm/token-transfer-core/dist/types/data/constants';

@Component({
  selector: 'app-token-transfer-form',
  templateUrl: './token-transfer-form.component.html',
  styleUrls: ['./token-transfer-form.component.sass'],
  animations: [collapseAnimation(250)],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TokenTransferFormComponent implements OnInit, OnDestroy {
  @Input() loading: ReplaySubject<boolean> = new ReplaySubject<boolean>(0);
  @Input() resetForm: Subject<boolean> = new Subject<boolean>();
  @Output() formSubmit = new EventEmitter<TransferTokenFormData>();
  feeErrorText$ = new BehaviorSubject<string>('');
  feeErrorDescription = '';
  isAmountError$ = new BehaviorSubject<boolean>(false);
  isWalletConnected$ = new BehaviorSubject(false);
  title$ = new BehaviorSubject('');
  message$ = new BehaviorSubject('');
  status$ = new BehaviorSubject<TokenTransferStatus>(TokenTransferStatus.form);
  ecosystemUrl = `https://neonevm.org/ecosystem`;
  formGroup: FormGroup;
  pendingStatusConfirmed = PendingStatus.confirmed;
  pendingStatusRejected = PendingStatus.rejected;
  private sub: SubscriptionLike[] = [];
  private _disabled = false;

  @ViewChild('tokenAmount') tokenAmount: TokenAmountComponent;

  get direction(): TransferDirection {
    return this.formGroup.get('direction')?.value;
  }

  get amount(): string {
    return this.formGroup.get('amount')?.value!;
  }

  get amountView(): string {
    const amount = this.amount;
    return amount.replace(/\.$/, '');
  }

  get token(): TransferToken {
    return this.formGroup.get('token')?.value;
  }

  get direction$(): Observable<TransferDirection> {
    return this.formGroup.valueChanges.pipe(filter(d => !!d.direction), map(d => d.direction));
  }

  get token$(): Observable<TransferToken> {
    return this.formGroup.valueChanges.pipe(filter(d => !!d.token), map(d => d.token));
  }

  get rewardFrom(): 'neon' | 'solana' {
    return this.formGroup.get('rewardFrom')?.value;
  }

  get formToken(): AbstractControl<TransferToken> {
    return this.formGroup.get('token')!;
  }

  get formAmount(): AbstractControl<string> {
    return this.formGroup.get('amount')!;
  }

  get formRewardFrom(): any {
    return this.formGroup.get('rewardFrom');
  }

  get formDirection(): any {
    return this.formGroup.get('direction');
  }

  get isEnoughTokens(): boolean {
    const amount = new Big(this.amount?.length > 0 ? this.amount : 0);
    const direction = this.direction;
    const rewardFrom = this.formRewardFrom.value;
    const transferFee = this.transferFee.transferFee$.value;
    const solanaBalance = this.solana.balance$.value;
    const token = this.token;
    if (direction === 'solana' && amount.gt(0) && this.priorityFee.data?.units && this.priorityFee.selected$.value) {
      const priorityFee = priorityFeeLamports(this.priorityFee.selected$.value?.amount, this.priorityFee.data?.units);
      if (rewardFrom === 'solana') {
        return solanaBalance.lt(amount.add(transferFee.solanaFee).add(priorityFee));
      } else {
        const tokenAmount = new Big(token.balance(direction).balance?.amount?.toString() ?? 0);
        const solPerNeon = this.pyth.solPerNeon;
        const neonPriorityFee = priorityFee.times(solPerNeon);
        return tokenAmount.lt(amount.add(transferFee.neonFee).add(neonPriorityFee));
      }
    }
    return false;
  }

  get disabled(): boolean {
    const d = this.formGroup.value;
    return this._disabled || new Big(this.amount?.length > 0 ? this.amount : 0).eq(0) ||
      this.isSolanaNEONTransfer && !this.formRewardFrom.value || this.isAmountError$.value ||
      this.transferFee.feeError$.value && (!this.isSolanaNEONTransfer || this.isSolanaNEONTransfer && d.rewardFrom !== 'neon') ||
      this.isEnoughTokens;
  }

  get isSolanaNEONTransfer(): boolean {
    const d = this.formGroup.value;
    return d.direction === TransferDirection.solana && d.token?.token.symbol === NEON;
  }

  get transferButtonText$(): Observable<string> {
    return this.isAmountError$.pipe(map(d => {
      if (d) {
        return `Insufficient ${this.token.token.symbol} balance`;
      } else {
        return this.isEnoughTokens ? 'Insufficient funds for gas' : 'Transfer';
      }
    }));
  }

  get isSolana(): boolean {
    return this.direction === TransferDirection.solana;
  }

  get network(): string {
    return environment.network;
  }

  get solanaHash$(): Observable<string> {
    return this.transfer.transferLog$.pipe(map(data => {
      if (data.transaction?.solana?.signature) {
        return data.transaction.solana.signature ?? ``;
      }
      return ``;
    }));
  }

  get neonHash$(): Observable<string> {
    return this.transfer.transferLog$.pipe(map(data => {
      if (data.transaction?.neon?.signature) {
        return data.transaction.neon.signature?.transactionHash ?? ``;
      }
      return ``;
    }));
  }

  get trackInExplorer(): Observable<string> {
    return (this.direction === TransferDirection.solana ? this.solanaHash$ : this.neonHash$).pipe(map(hash => {
      return this.direction === TransferDirection.solana ? this.solanaUrl(hash) : this.neonUrl(hash);
    }));
  }

  get transferToo(): string {
    return this.direction === TransferDirection.solana ? 'Neon EVM' : 'Solana';
  }

  get wallet(): Observable<string> {
    return this.direction === TransferDirection.solana ? this.neon.addressView$ : this.solana.pubkey$;
  }

  get error$(): Observable<string> {
    return this.transfer.transferLog$.pipe(map(data => {
      if (data.error) {
        if (data.error instanceof TransactionExpiredBlockheightExceededError) {
          return `Due to the current high load on Solana,<br /> we recommend increasing the Priority fee to "Fast" using our Advanced mode feature.`;
        }
        const m = data.error instanceof HttpErrorResponse ? data.error.error.error?.message ?? data.error?.message : data.error?.message ?? ERROR_MESSAGE;
        const message = m.split('\n');
        return message?.length > 1 ? message[0] : message;
      }
      return ERROR_MESSAGE;
    }));
  }

  unloadedHandler = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    return event;
  };

  solanaUrl = (hash: string): string => {
    return hash ? `https://solscan.io/tx/${hash}${this.network != 'mainnet' ? `?cluster=${this.network}` : ''}` : '';
  };

  neonUrl = (hash: string): string => {
    return hash ? `https://${this.network === 'devnet' ? 'devnet.' : ''}neonscan.org/tx/${hash}` : '';
  };

  retryTransfer(): void {
    this.status$.next(TokenTransferStatus.form);
    this.formAmount.setValue('');
    setTimeout(() => {
      this.formToken.setValue(this.token);
      this.formDirection.setValue(this.direction);
    }, 100);
  }

  onSubmit(): void {
    this.status$.next(TokenTransferStatus.transfer);
    this.formSubmit.emit(this.formGroup.value);
  }

  handleClick(event: string): void {
    this.ga.event(event);
  }

  directionChange(data: TransferDirection): void {
    this.formAmount?.setValue('');
    this.formGroup.get('direction')?.setValue(data);
    const symbols = this.direction === TransferDirection.solana ? [W_NEON] : [SOL];
    if (symbols.includes(this.token?.token?.symbol)) {
      this.isAmountError$.next(false);
    }
  }

  maxAmount(token: TransferToken): void {
    if (this.direction === TransferDirection.neon && token?.token.symbol === NEON) {
      const amount = token.amountView(this.direction);
      this.formAmount.setValue((amount.gt(0.015) ? amount.minus(0.015).toFixed(9) : amount.toFixed(9)).toString());
    } else if (this.direction === TransferDirection.solana && token.token.symbol === SOL) {
      const amount = token.amountView(this.direction);
      this.formAmount.setValue((amount.gt(0.01) ? amount.minus(0.01) : amount).toString());
    } else {
      const amount = token.amountView(this.direction);
      const amountFix = amount.toFixed(token.token.decimals, 0);
      const amountStr = amount.toString();
      this.formAmount.setValue(amountFix.includes(amountStr) ? amountStr : amountFix);
    }
  }

  chainSwitch(): void {
    this.sub.push(this.chain.chainSwitch().pipe(tap(() => {
      this.cdr.detectChanges();
    })).subscribe());
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      direction: [TransferDirection.solana, Validators.required],
      token: [null, Validators.required],
      amount: ['', Validators.required],
      rewardAmount: [null],
      rewardFrom: ['neon'],
      priorityFee: [1]
    });
    this.formAmount?.disable();
    this.formGroup.markAsDirty();
    this.sub.push(combineLatest([this.neon.connected$, this.solana.connected$, this.neon.isSameNetwork$]).pipe(tap(([a, b]) => {
      if (a && b) {
        this.formAmount?.enable();
        this.tokenAmount?.onClick();
      } else {
        this.formAmount?.setValue('');
        this.formAmount?.disable();
        this.transferFee.feeError$.next(false);
        this.isAmountError$.next(false);
      }
    })).subscribe());
    this.sub.push(this.formToken.valueChanges.pipe(
      distinctUntilChanged((a: TransferToken, b) => a?.token.name === b?.token.name),
      tap(token => {
        if (token instanceof TransferToken) {
          this.formRewardFrom?.setValue(token.token.symbol === NEON ? 'neon' : 'solana');
        } else {
          this.formRewardFrom?.setValue('solana');
        }
        this.transferFee.feeError$.next(false);
      })).subscribe());
    this.sub.push(this.formGroup.valueChanges.pipe(
      distinctUntilChanged((p, c) => p.amount === c.amount && p.rewardFrom === c.rewardFrom && p.token === c.token),
      tap((data: TransferTokenFormData) => {
        const { token } = data;
        if (token instanceof TransferToken) {
          const { status } = token.balance(this.direction);
          this.formAmount.setValidators([
            Validators.required,
            FormValidators.maxBig(status === 'loaded' ? token.amountView(this.direction) : new Big(0))
          ]);
        }
      }),
      mergeMap((data: TransferTokenFormData) => {
        this._disabled = true;
        if (Number(data.amount) > 0) {
          return this.transferFee.transferFee(data);
        } else {
          return this.transferFee.transferFeeClean();
        }
      }), tap(this.enable)).subscribe());
    this.sub.push(this.loading.pipe(tap((loading) => {
      if (loading) {
        this.formGroup.disable();
      } else {
        this.formGroup.enable();
      }
      this.cdr.markForCheck();
    })).subscribe());
    this.sub.push(combineLatest<[Big, Big, TransferTokenFee, any]>([this.neon.balance$, this.solana.balance$, this.transferFee.transferFee$, this.formGroup.valueChanges]).pipe(
      throttleTime(100),
      tap(([neon, solana, { solanaFee, neonFee }, { amount, token, direction }]) => {
        if (amount) {
          const tokenAmount = token.toAmount(amount, direction);
          const solBalance = token.token.symbol === SOL ? solana.lt(tokenAmount.add(solanaFee)) : solana.lt(solanaFee);
          const neonBalance = token.token.symbol === NEON ? neon.lt(tokenAmount.add(neonFee)) : neon.lt(neonFee);
          if (tokenAmount.gt(0) && tokenAmount.gt(token.amount(direction))) {
            this.isAmountError$.next(true);
          } else {
            this.isAmountError$.next(false);
          }
          const message = `Not enough `;
          if (!this.chain.isSupportedChain) {
            this.transferFee.feeError$.next(true);
            this.feeErrorText$.next(`Transfer isn't supported on this network, please switch network`);
          } else if (this.direction === TransferDirection.solana && solanaFee.gt(0) && solBalance) {
            this.transferFee.feeError$.next(true);
            this.feeErrorText$.next(`Not enough SOL in your Solana wallet`);
          } else if (this.direction === TransferDirection.neon && neonFee.gt(0) && neonBalance) {
            this.transferFee.feeError$.next(true);
            this.feeErrorText$.next(`Not enough NEON in your NEON wallet`);
            this.feeErrorDescription = `For this transaction, you need a minimum of ${toAmountView(neonFee, NEON_TOKEN_MINT_DECIMALS)} NEON to pay the transaction fee.`;
          } else if (this.direction === TransferDirection.neon && neonFee.gt(0) && solanaFee.gt(0) && (solBalance || neonBalance)) {
            this.transferFee.feeError$.next(true);
            this.feeErrorText$.next(`${message} SOL and NEON in your wallets`);
          } else if (this.transferFee.feeErrorText$.value) {
            this.transferFee.feeError$.next(true);
            this.feeErrorText$.next(this.transferFee.feeErrorText$.value);
          } else {
            this.transferFee.feeError$.next(false);
            this.transferFee.feeErrorText$.next('');
            this.feeErrorText$.next('');
          }
        }
      })).subscribe());
    this.sub.push(this.tokens.tokens$.pipe(filter(d => d.length > 0), first(), tap(d => {
      this.formToken?.setValue(d[0]);
    })).subscribe());
    this.sub.push(combineLatest([this.formAmount.valueChanges, this.neon.isSameNetwork$])
      .pipe(tap(([amount, isSameNetwork]) => {
        if (amount.length > 0 && isSameNetwork) {
          const token = this.token;
          const amountBig = new Big(amount);
          const tokenAmount = token.amountView(this.direction);
          this.isWalletConnected$.next(tokenAmount.gt(0) && amountBig.gt(0) && tokenAmount.gte(amountBig));
        } else {
          this.isWalletConnected$.next(false);
        }
      })).subscribe());

    this.sub.push(this.transfer.pendingStatus$.pipe(tap(status => {
      switch (status) {
        case PendingStatus.wrap: {
          this.title$.next('Transfer in progress...');
          this.message$.next(`Wait when the token will wrapped`);
          break;
        }
        case PendingStatus.unwrap: {
          this.title$.next('Transfer in progress...');
          this.message$.next(`Wait when the token will unwrapped`);
          break;
        }
        case PendingStatus.started: {
          this.title$.next('Transfer in progress...');
          this.message$.next(`Please sign transaction`);
          break;
        }
        case PendingStatus.signed: {
          this.message$.next(`Usually, this process takes up to 30 seconds to complete.<br /> Please do not close the browser window yet.`);
          break;
        }
        case PendingStatus.confirmed: {
          this.title$.next('Transfer Completed');
          firstValueFrom(this.wallet).then(wallet => {
            if (this.token) {
              const direction = this.direction === TransferDirection.solana ? TransferDirection.neon : TransferDirection.solana;
              const priorityFee = this.priorityFee.selected$.value;
              const amount = this.priorityFee.receiveFeeCalc(priorityFee, this.amount, this.token.symbol, this.direction, this.rewardFrom);
              const token = this.token.amountView(direction).add(amount);
              const fraction = fractionLength(token.toNumber());
              this.message$.next(`There are now ${token.toFixed(fraction)} ${this.token.symbol} in ${wallet}<br />wallet on ${this.transferToo} Network`);
            }
          });
          break;
        }
        case PendingStatus.rejected: {
          firstValueFrom(this.error$).then(error => {
            if (error.includes('User rejected the request.')) {
              this.title$.next('Transaction canceled');
            } else {
              this.title$.next('Transaction has failed');
            }
            this.message$.next(error);
          });
          break;
        }
      }
    })).subscribe());
    this.sub.push(this.transfer.pendingStatus$.pipe(tap(status => {
      switch (status) {
        case PendingStatus.started:
          window.addEventListener('beforeunload', this.unloadedHandler);
          break;
        case PendingStatus.confirmed:
        case PendingStatus.rejected:
          window.removeEventListener('beforeunload', this.unloadedHandler);
          break;
      }
    })).subscribe());
    this.transaction.init();
    this.neonFee.init();
  }

  ngOnDestroy(): void {
    this.transaction.destroy();
    this.neonFee.destroy();
    itemUnsubscribe(this.sub);
  }

  private enable = () => {
    this._disabled = false;
  };

  constructor(public tokens: TokensListService, public transferFee: TokenTransferFeeService, public chain: NeonChainService,
              public neon: WalletConnectService, public transfer: TokenTransferService, private solana: SolanaWalletService,
              private transaction: TransferTransactionService, private priorityFee: PriorityFeeService, private neonFee: NeonTransferFeeService,
              private pyth: PythService, private fb: FormBuilder, private cdr: ChangeDetectorRef, private ga: GoogleAnalyticsService) {
  }
}
