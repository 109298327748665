import { AbstractControl } from '@angular/forms';
import { Big } from 'big.js';

export class FormValidators {
  static maxBig(value: Big) {
    return (control: AbstractControl) => {
      if (new Big(control.value ? control.value : 0).gt(value)) {
        return { max: true };
      }
      return null;
    };
  }
}
