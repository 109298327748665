import { Injectable } from '@angular/core';
import { LAMPORTS_PER_SOL, Transaction } from '@solana/web3.js';
import { NEON_TOKEN_MINT_DECIMALS } from '@neonevm/token-transfer-core';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  delay,
  firstValueFrom,
  forkJoin,
  from,
  map,
  Observable,
  of,
  SubscriptionLike,
  switchMap,
  tap
} from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Transaction as TransactionConfig } from 'web3-types';
import { Big } from 'big.js';
import { Address } from 'abitype';
import {
  itemUnsubscribe,
  NEON,
  NEON_USD,
  neonTransferFeeBig,
  priorityFeeLamports,
  SOL,
  SOL_USD,
  TRANSACTION_FEE,
  W_NEON,
  W_SOL
} from '../../utils';
import { environment } from '../../environments/environment';
import {
  MultiTokenFee,
  MultiTokenGasFee,
  TransferDirection,
  TransferTokenFee,
  TransferTokenFormData
} from '../../models';
import { TokenTransferService } from './token-transfer.service';
import { NeonTransferFeeService } from './neon-transfer-fee.service';
import { PriorityFeeService } from './priority-fee.service';
import { NeonChainService } from './neon-chain.service';
import { PythService } from './pyth.service';

@Injectable({ providedIn: 'root' })
export class TokenTransferFeeService {
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  feeError$ = new BehaviorSubject<boolean>(false);
  feeErrorText$ = new BehaviorSubject<string>('');

  neonFees$: BehaviorSubject<MultiTokenFee[]> = new BehaviorSubject<MultiTokenFee[]>([]);

  private _transferFee$: BehaviorSubject<TransferTokenFee> = new BehaviorSubject<TransferTokenFee>(this.transferFeeDefault);
  private _zeroFee = new Big(0);
  private subs: SubscriptionLike[] = [];

  get zeroFee(): Big {
    return new Big(0);
  }

  get transferFeeDefault(): TransferTokenFee {
    return { solanaFee: new Big(0), neonFee: new Big(0) };
  }

  get transferFee$(): BehaviorSubject<TransferTokenFee> {
    return this._transferFee$;
  }

  get transferFeeView$(): Observable<TransferTokenFee> {
    return this.transferFee$.pipe(map(({ solanaFee, neonFee }) => ({
      solanaFee: solanaFee.div(LAMPORTS_PER_SOL),
      neonFee: neonFee.div(new Big(10).pow(NEON_TOKEN_MINT_DECIMALS))
    })));
  }

  transferFee(data: TransferTokenFormData): Observable<Big[]> {
    const result: Observable<Big>[] = [];
    this.loading$.next(true);
    switch (data.token.token.symbol) {
      case W_NEON: {
        result.push(of(this.zeroFee), from(this.neonFee(data)));
        break;
      }
      case NEON: {
        switch (data.direction) {
          case TransferDirection.neon:
            result.push(of(this.zeroFee), from(this.neonFee(data)));
            break;
          case TransferDirection.solana:
            result.push(from(this.solanaFee(data)), of(this.zeroFee));
            break;
        }
        break;
      }
      default: {
        switch (data.direction) {
          case TransferDirection.neon:
            result.push(from(this.solanaFee(data)), from(this.neonFee(data)));
            break;
          case TransferDirection.solana:
            result.push(from(this.solanaFee(data)), of(this.zeroFee));
            break;
        }
      }
    }
    return forkJoin(result).pipe(tap(([solanaFee, neonFee]) => {
      this._transferFee$.next({ solanaFee, neonFee });
    }), catchError(_ => {
      this._transferFee$.next({ solanaFee: new Big(0), neonFee: new Big(0) });
      this.feeError$.next(true);
      return of([]);
    }), finalize(() => {
      this.loading$.next(false);
    }));
  }

  //Transfer SOL from Solana -> Neon
  //Transfer WSOL from Neon -> Solana
  async solanaMultiFee(data: TransferTokenFormData): Promise<MultiTokenFee[]> {
    const result: MultiTokenFee = { network: 'solana', token: SOL, fee: new Big(0) };
    return new Promise(async (resolve, reject) => {
      try {
        if (data.direction === TransferDirection.neon && this.transfer.newAccountFee$.value) {
          await this.solanaTransaction(data);
          const value = 14800;
          const newAccountFee = 1385040;
          switch (data.token.token.symbol) {
            case NEON:
              result.fee = new Big(newAccountFee + (value ?? 0));
              break;
            default:
              result.fee = new Big(value ?? 0);
          }
        } else {
          const transaction = await this.solanaTransaction({ ...data, rewardFrom: 'solana' });
          if (transaction.instructions.length > 0) {
            await this.fee.priorityFee(transaction);
            const balance = await this.transfer.solana.connection.getFeeForMessage(transaction.compileMessage());
            result.fee = new Big(balance?.value ?? 0);
          } else {
            result.fee = this._zeroFee;
          }
        }
        resolve([result]);
      } catch (e) {
        // console.log(e);
        reject(e);
      }
    });
  }

  async neonMultiFee(data: TransferTokenFormData): Promise<MultiTokenFee[]> {
    const tokens = await firstValueFrom(this.chain.tokenList$);
    const transaction = await this.neonTransaction(data);
    const result: MultiTokenFee[] = [];
    for (const token of tokens) {
      const multiTokenGasFee = new MultiTokenGasFee(token);
      const {
        gas,
        gasPrice
      } = await this.transfer._getGasPrice(transaction, `${environment.urls.neon}/${token.tokenName.toLowerCase()}`);
      multiTokenGasFee.setFee(new Big(gas).times(new Big(gasPrice)));
      result.push(multiTokenGasFee);
    }
    return result;
  }

  solanaNEONTransferFee(data: TransferTokenFormData): Observable<Big[]> {
    this.loading$.next(true);
    return combineLatest([from(this.solanaFee(data)), this.neonTransferFee.neonTransferFee()]).pipe(
      switchMap(async ([solanaFee, { operator_fee, sol_price_usd, neon_price_usd }]) => {
        try {
          const solUsd = (parseInt(sol_price_usd, 16) > 0 ? parseInt(sol_price_usd, 16) : SOL_USD) / 100;
          const neonUsd = (parseInt(neon_price_usd, 16) > 0 ? parseInt(neon_price_usd, 16) : NEON_USD) / 100;
          const neonFee = neonTransferFeeBig(operator_fee, solUsd, neonUsd, solanaFee.toString());
          this._transferFee$.next({ solanaFee, neonFee });
          return [solanaFee, neonFee];
        } catch (e) {
          this._transferFee$.next({ solanaFee, neonFee: this._zeroFee });
          return [solanaFee, this._zeroFee];
        }
      }), catchError(_ => {
        this._transferFee$.next({ solanaFee: this._zeroFee, neonFee: this._zeroFee });
        this.feeError$.next(true);
        return of([this._zeroFee, this._zeroFee]);
      }), finalize(() => {
        this.loading$.next(false);
      }));
  }

  async solanaFee(data: TransferTokenFormData): Promise<Big> {
    return new Promise(async (resolve, reject) => {
      try {
        if (data.direction === TransferDirection.neon && this.transfer.newAccountFee$.value) {
          await this.solanaTransaction(data);
          const value = 14800;
          const newAccountFee = 1385040;
          switch (data.token.token.symbol) {
            case NEON:
              resolve(new Big(newAccountFee + (value ?? 0)));
              break;
            default:
              resolve(new Big(value ?? 0));
          }
        } else {
          const transaction = await this.solanaTransaction({ ...data, rewardFrom: 'solana' });
          if (transaction.instructions.length > 0) {
            await this.fee.priorityFee(transaction);
            resolve(new Big(TRANSACTION_FEE));
          } else {
            resolve(this._zeroFee);
          }
        }
      } catch (e) {
        reject(e);
      }
    });
  }

  async neonFee(data: TransferTokenFormData): Promise<Big> {
    return new Promise(async (resolve, reject) => {
      try {
        const transaction = await this.neonTransaction(data);
        const { gas, gasPrice } = await this.transfer.getGasPrice(transaction);
        resolve(new Big(gas).times(new Big(gasPrice)));
      } catch (e) {
        reject(e);
      }
    });
  }

  neonTransaction(data: TransferTokenFormData): Promise<TransactionConfig> {
    const { amount, token: { token } } = data;
    switch (token.symbol) {
      case W_NEON:
        return this.transfer.unwrapWNEONInNeonTransaction(amount, token);
      case NEON:
        return this.transfer.transferNEONToSolanaTransaction(amount, token, environment.neon.token_contract as Address);
      case SOL:
        if (this.transfer.isSolNetwork) {
          return this.transfer.transferNEONToSolanaTransaction(amount, token, environment.neon.token_contract_sol as Address);
        }
        break;
    }
    return this.transfer.transferERC20ToSolanaNeonTransaction(amount, token);
  }

  async solanaTransaction(data: TransferTokenFormData): Promise<Transaction> {
    const { amount, direction, rewardFrom, token: { token } } = data;
    if (this.transfer.isSolNetwork) {
      switch (token.symbol) {
        case W_SOL:
          return this.transfer.transferWSOLToNeonTransaction(amount, token);
        case SOL:
          return this.transfer.transferSOLToNeonTransaction(amount, token);
      }
    } else {
      switch (token.symbol) {
        case SOL:
          if (direction === TransferDirection.solana) {
            return this.transfer.transferWSOLToNeonTransaction(amount, token);
          }
          break;
        case NEON: {
          if (rewardFrom === 'neon') {
            const priorityFee = this.fee.selected$.value.amount; // micro-lamports
            const units = this.fee.data.units;
            const pFee = priorityFeeLamports(priorityFee, units);
            const solPerNeon = this.pyth.solPerNeon;
            const neon = this.pyth.neonPrice;
            const solCommonFee = pFee.add(TRANSACTION_FEE);
            const rewardAmountAndFee = solCommonFee.times(solPerNeon).times(neon).round();
            const sendAmount = new Big(amount.toString()).times(LAMPORTS_PER_SOL).minus(rewardAmountAndFee).div(LAMPORTS_PER_SOL).round();
            const serviceWallet = await firstValueFrom(this.neonTransferFee.serviceWallet$);
            return this.transfer.transferNEONToNeonWithNeonFeeTransaction(sendAmount.toString(), token, serviceWallet!, rewardAmountAndFee.toString(), priorityFee);
          } else {
            return this.transfer.transferNEONToNeonTransaction(amount, token);
          }
        }
      }
    }
    return data.direction === TransferDirection.neon ?
      this.transfer.transferERC20ToSolanaSolanaTransaction(amount, token) :
      this.transfer.transferERC20ToNeonTransaction(amount, token);
  }

  transferFeeClean(): Observable<TransferTokenFee> {
    this._transferFee$.next(this.transferFeeDefault);
    this.feeErrorText$.next('');
    return this._transferFee$.pipe(delay(100));
  }

  init(): void {
    this.subs.push(this.chain.tokenList$.pipe(tap(d => {
      const result: MultiTokenFee[] = d.map(i => new MultiTokenGasFee(i));
      this.neonFees$.next(result);
    })).subscribe());
  }

  destroy(): void {
    itemUnsubscribe(this.subs);
  }

  constructor(private transfer: TokenTransferService, private neonTransferFee: NeonTransferFeeService,
              private fee: PriorityFeeService, private chain: NeonChainService, private pyth: PythService) {
  }
}
