import { Injectable } from '@angular/core';
import { LedgerWalletAdapter } from '@solana/wallet-adapter-ledger';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-phantom';
import { SolflareWalletAdapter } from '@solana/wallet-adapter-solflare';
import { WalletConnectWalletAdapter } from '@solana/wallet-adapter-walletconnect';
import {
  StandardWalletAdapter
} from "@solana/wallet-standard-wallet-adapter-base";
import { getWallets, Wallets } from "@wallet-standard/core";
import { isWalletAdapterCompatibleStandardWallet, WalletAdapterCompatibleStandardWallet } from "@solana/wallet-adapter-base";
import { SolanaWallet, Wallet } from '../../models';
import { wcConfig } from '../../utils';

@Injectable({ providedIn: 'root' })
export class SolanaWalletsService {
  providers: SolanaWallet[] = [];
  private _walletList: Wallets = getWallets();

  getWalletProvider(name: string): StandardWalletAdapter | null {
    const wallet = this._walletList.get().filter((newWallet) => newWallet.name === name);
    if(wallet.length && isWalletAdapterCompatibleStandardWallet(wallet[0])) {
      return new StandardWalletAdapter({ wallet: <WalletAdapterCompatibleStandardWallet>wallet[0] })
    }
    return null;
  }

  wallets: Wallet<any>[] = [
    { id: 0, name: 'Phantom', icon: 'phantom', provider: new PhantomWalletAdapter(), link: `https://phantom.app` },
    { id: 1, name: 'Solflare', icon: 'solflare', provider: new SolflareWalletAdapter(), link: `https://solflare.com` },
    { id: 3, name: 'Ledger', icon: 'ledger', provider: new LedgerWalletAdapter(), link: `https://www.ledger.com` },
    { id: 4, name: 'BackPack', icon: 'backpack', provider: this.getWalletProvider('Backpack') },
    { id: 5, name: 'WalletConnect', icon: 'walletconnect', provider: new WalletConnectWalletAdapter(wcConfig), link: `https://walletconnect.com` }
  ];

  trackByFn = (i: number, w: any): number => w.id ?? i;

  constructor() {
    this.providers.push(...this.wallets.map(({ provider }) => provider));
  }
}
