import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, switchMap, tap, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { TransferToken } from '../../models';
import { NotificationService } from '../../notifications';
import { WalletConnectService } from './wallet-connect.service';

@Injectable()
export class TokenAccessService {
  loading$ = new BehaviorSubject<boolean>(false);

  watchAccess({ token: { address, symbol, decimals, logoURI: image } }: TransferToken): Observable<any> {
    this.loading$.next(true);
    return this.neon.provider$.pipe(switchMap(p => from(p.request({
      method: 'wallet_watchAsset',
      params: { type: 'ERC20', options: { address, symbol, decimals, image } }
    }))), tap(() => {
      this.n.success({ title: `The ${symbol} was added successfully` });
    }), catchError(e => {
      this.n.error({ title: e.message ?? `The ${symbol} wasn't added` });
      return throwError(e);
    }), finalize(() => this.loading$.next(false)));
  }

  constructor(private neon: WalletConnectService, private n: NotificationService) {
  }
}
