export class Counter {
  counter: any;
  sequence = [9, 8, 7, 6, 5, 4, 3, 2, 1, 0].join('\n');
  pattern = /(\d|\.)|[A-Za-z\s\W]+/g;

  constructor(element: Element) {
    this.counter = element;
  }

  countUp(textContent: string): void {
    let result: string[] = textContent.match(this.pattern) ?? [];
    this.initTemplate(result);
  }

  private initTemplate(items: string[]): void {
    const digits = this.counter.getElementsByClassName('counter-digit');
    for (let i = 0; i < items.length; i++) {
      const digit = digits.item(i);
      const place = parseInt(items[i]);
      if (/\d+/.test(items[i])) {
        if (digit) {
          if (digit.children.length === 0) {
            digit.insertAdjacentHTML(`beforebegin`, `<div class='counter-digit'><div class='counter-sequence'>${this.sequence}</div></div>`);
            digit.remove();
            setTimeout(() => {
              digits[i].children.item(0).style.transform = `translate3d(0,${-(9 - place) * 10}%,0)`;
            });
          } else {
            digit.children.item(0).style.transform = `translate3d(0,${-(9 - place) * 10}%,0)`;
          }
        } else {
          this.counter.insertAdjacentHTML(`beforeend`, `<div class='counter-digit'><div class='counter-sequence'>${this.sequence}</div></div>`);
          setTimeout(() => {
            digits[i].children.item(0).style.transform = `translate3d(0,${-(9 - place) * 10}%,0)`;
          });
        }
      } else {
        if (digit) {
          digit.insertAdjacentHTML(`afterend`, `<div class='counter-digit'>${items[i]}</div>`);
          digit.remove();
        } else {
          this.counter.insertAdjacentHTML(`beforeend`, `<div class='counter-digit'>${items[i]}</div>`);
        }
      }
    }
    if (digits.length > items.length) {
      while (digits.length > items.length) {
        this.counter.removeChild(digits[items.length]);
      }
    }
  }
}
