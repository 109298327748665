export function stringShort(str: string, max = 15, concat = '..'): string {
  const len = str.length;
  if (len > max) {
    const half = Math.floor((max - concat.length) / 2);
    return `${str.slice(0, half)}${concat}${str.slice(str.length - half)}`;
  }
  return str;
}

export function parseBigInt(key: string, value: any) {
  return typeof value === 'bigint' ? value.toString() : value; // return everything else unchanged
}
