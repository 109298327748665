@if (error) {
  <div class='error'>
    <img src='/assets/icons/notification-error.svg' class='icon' alt='Transfer error' width='38' height='38' />
    <div class='flex flex-col gap-[4px] w-full'>
      <h3 class='title'>{{ error }}</h3>
      @if (message) {
        <p class='message' [innerHTML]='message'></p>
      }
    </div>
  </div>
}
