import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LAMPORTS_PER_SOL } from '@solana/web3.js';
import { BehaviorSubject, combineLatest, Observable, of, SubscriptionLike, tap } from 'rxjs';
import { Big } from 'big.js';
import { TransferDirection } from '../../../models';
import { fractionLength, itemUnsubscribe, NEON, priorityFeeLamports, SOL, TRANSACTION_FEE } from '../../../utils';
import { PriorityFeeService, PythService, TokenTransferFeeService, TokenTransferService } from '../../services';

@Component({
  selector: 'app-transfer-gas-fees',
  templateUrl: './transfer-gas-fees.component.html',
  styleUrl: './transfer-gas-fees.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransferGasFeesComponent implements OnInit, OnDestroy {
  @Input() direction: TransferDirection;
  @Input() reward: 'solana' | 'neon' = 'solana';
  @Input() loading: Observable<boolean> = of(false);
  private subs: SubscriptionLike[] = [];

  transferFee$ = new BehaviorSubject('');
  transferFeeUSD$ = new BehaviorSubject('');

  ngOnInit() {
    this.subs.push(combineLatest([this.fee.transferFeeView$, this.priorityFee.selected$, this.priorityFee.data$]).pipe(
      tap(([{ solanaFee, neonFee }, priorityFee, data]) => {
        let sFee = '';
        let nFee = '';
        let sFeeBig = new Big(0);
        let nFeeBig = new Big(0);
        if (this.direction === TransferDirection.solana) {
          if (priorityFee?.amount > 0) {
            const pFee = priorityFeeLamports(priorityFee.amount, data.units);
            sFeeBig = pFee.add(TRANSACTION_FEE).div(LAMPORTS_PER_SOL);
            if (this.reward === 'neon') {
              const solPerNeon = this.pyth.solPerNeon;
              const neon = this.pyth.neonPrice;
              const fee = sFeeBig.times(solPerNeon).times(neon);
              const fraction = fractionLength(fee.toNumber());
              sFee = `${fee.toFixed(fraction)} ${NEON}`;
            } else {
              const fraction = fractionLength(sFeeBig.toNumber());
              sFee = `${sFeeBig.toFixed(fraction)} ${SOL}`;
            }
          }
        } else {
          if (solanaFee.gt(0)) {
            sFeeBig = new Big(solanaFee.div(LAMPORTS_PER_SOL));
            const fraction = fractionLength(sFeeBig.toNumber());
            sFee = `${sFeeBig?.toFixed(fraction)} ${SOL}`;
          }
          if (neonFee?.gt(0)) {
            nFeeBig = new Big(neonFee?.toFixed(6) ?? 0);
            const fraction = fractionLength(nFeeBig.toNumber());
            nFee = `${nFeeBig.toFixed(fraction)} ${NEON}`;
          }
        }
        if (this.direction === TransferDirection.solana) {
          this.transferFeeUSDCalc(sFeeBig, SOL);
        } else {
          this.transferFeeUSDCalc(nFeeBig, NEON);
        }
        this.transferFee$.next(sFee || nFee ? `${sFee ? `${sFee} ` : ''}${nFee ? `${sFee ? '+ ' : ''}${nFee}` : ''}` : '');
      })).subscribe());
  }

  transferFeeUSDCalc(fee: Big, symbol: string): void {
    const price = symbol === SOL ? this.pyth.solPrice : this.pyth.neonPrice;
    const feeUSD = fee.times(price);
    const fraction = fractionLength(feeUSD.toNumber());
    this.transferFeeUSD$.next(feeUSD.gt(0) ? `$${feeUSD.lte(1e-4) ? '<0.0001' : parseFloat(feeUSD.toFixed(fraction < 4 ? fraction : 4))}` : '');
  }


  ngOnDestroy() {
    itemUnsubscribe(this.subs);
  }

  constructor(public transfer: TokenTransferService, private pyth: PythService, private fee: TokenTransferFeeService,
              private priorityFee: PriorityFeeService) {
  }
}
