import { Injectable } from '@angular/core';
import { createWeb3Modal, Web3Modal } from '@web3modal/wagmi';
import {
  Config,
  createConfig,
  disconnect,
  getAccount,
  getBalance,
  GetBalanceReturnType,
  getChainId,
  getPublicClient,
  http,
  reconnect,
  sendTransaction,
  SendTransactionParameters,
  signMessage,
  waitForTransactionReceipt,
  WaitForTransactionReceiptReturnType,
  watchAccount,
  watchChainId
} from '@wagmi/core';
import { injected, walletConnect } from '@wagmi/connectors';
import { BehaviorSubject, delay, filter, from, map, Observable, ReplaySubject, Subject, switchMap, tap } from 'rxjs';
import { Address } from 'abitype';
import { Chain, Hex } from 'viem';
import { Big } from 'big.js';
import Web3 from 'web3';
import { environment } from '../../environments/environment';
import {
  DataStorage,
  EXCLUDED_WALLETS,
  metadata,
  NEON_WALLETS,
  neonDevnet,
  neonMainnet,
  neonSolDevnet,
  neonSolMainnet,
  TRANSACTION_TIMEOUT
} from '../../utils';
import { NotificationService } from '../../notifications';
import { NeonWallet } from '../../models';
import { HttpRpcClient } from '../../app/services';

@Injectable({ providedIn: 'root' })
export class WalletConnectService extends DataStorage<any> {
  web3: Web3;
  web3$: ReplaySubject<Web3> = new ReplaySubject<Web3>(0);
  projectId: string = environment.walletConnect.projectId;
  address$: BehaviorSubject<Address> = new BehaviorSubject<Address>(undefined!);
  balance$: BehaviorSubject<Big> = new BehaviorSubject<Big>(new Big(0));
  wallet$: BehaviorSubject<NeonWallet> = new BehaviorSubject<NeonWallet>(undefined!);
  chainId$: BehaviorSubject<number> = new BehaviorSubject<number>(environment.chainId!);
  provider$: ReplaySubject<any> = new ReplaySubject<any>(0);
  refresh$: Subject<boolean> = new Subject<boolean>();
  connected$ = new BehaviorSubject<boolean>(false);
  isSameNetwork$ = new ReplaySubject<boolean>(0);
  chains: Chain[] = [neonDevnet, neonMainnet] as const;
  web3Modal: Web3Modal;
  wagmiConfig: Config;
  neonEvmUrl = this.api.rpcUrl;

  get address(): `0x${string}` {
    return this.address$.value;
  }

  get addressView$(): Observable<string> {
    return this.address$.pipe(map(d => {
      if (d?.length) {
        return `${d.slice(0, 7)}..${d.slice(-5)}`;
      }
      return '';
    }));
  }

  get chainId(): number {
    return Number(getChainId(this.wagmiConfig));
  }

  get publicClient(): any {
    return getPublicClient(this.wagmiConfig);
  }

  setProvider = (p: any) => {
    this.provider$.next(p);
    this.web3 = new Web3(p);
    this.web3$.next(this.web3);
    this.isSameNetworkEmit();
  };

  getBalance(address: Address, chainId: number): Observable<GetBalanceReturnType> {
    return from(getBalance(this.wagmiConfig, { address, chainId }));
  }

  getTransactionReceipt = async (transaction: SendTransactionParameters | any, chainId: number): Promise<WaitForTransactionReceiptReturnType> => {
    const result = await sendTransaction(this.wagmiConfig, transaction);
    return waitForTransactionReceipt(this.wagmiConfig, { chainId, hash: result, timeout: TRANSACTION_TIMEOUT });
  };

  isSameNetworkEmit(): void {
    this.subs.push(this.chainId$.pipe(tap(chainId => {
      this.isSameNetwork$.next(Number(chainId) === environment.chainId);
    })).subscribe());
  }

  open(): void {
    this.web3Modal.open();
  }

  signMessage(message: string): Observable<Hex | string> {
    return new Observable<Hex>(subscriber => {
      signMessage(this.wagmiConfig, { message })
        .then(hex => subscriber.next(hex))
        .catch(e => subscriber.next(e))
        .finally(() => subscriber.complete());
    });
  }

  walletBalance(address: Address): Observable<Big> {
    return this.chainId$.pipe(filter(c => c === environment.chainId), switchMap(chainId => {
      return from(getBalance(this.wagmiConfig, { address, chainId })).pipe(map(b => {
        const balance = new Big(b.value.toString());
        this.balance$.next(balance);
        return balance;
      }));
    }));
  }

  walletBalanceClean(): Observable<Big> {
    this.balance$.next(new Big(0));
    return this.balance$.pipe(delay(100));
  }

  async disconnect(): Promise<any> {
    if (getAccount(this.wagmiConfig).isConnected) {
      await disconnect(this.wagmiConfig);
    }
  }

  init(): void {
    const projectId = this.projectId;

    const connectors = [
      walletConnect({ projectId, metadata, showQrModal: false }),
      injected({ shimDisconnect: false })
    ];

    const transports = {
      [neonDevnet.id]: http(),
      [neonMainnet.id]: http(),
      [neonSolDevnet.id]: http(),
      [neonSolMainnet.id]: http()
    };

    this.wagmiConfig = createConfig({
      chains: this.chains as [Chain, ...Chain[]],
      transports,
      connectors,
      multiInjectedProviderDiscovery: true,
      syncConnectedChain: true
    });
    reconnect(this.wagmiConfig).then((connects => {
      if (connects.length) {
        const connect = connects[0];
        if (this.chainId$.value !== connect.chainId) {
          this.chainId$.next(connect?.chainId);
        }
      }
    }));

    this.web3Modal = createWeb3Modal({
      wagmiConfig: this.wagmiConfig,
      projectId,
      featuredWalletIds: NEON_WALLETS.filter(d => d.name !== 'Taho').map(w => w.id),
      includeWalletIds: NEON_WALLETS.map(d => d.id),
      excludeWalletIds: EXCLUDED_WALLETS
    });

    watchAccount(this.wagmiConfig, {
      onChange: (account) => {
        this.connected$.next(account.isConnected);
        if (account.isConnected) {
          this.address$.next(account.address!);
          if (this.chainId$.value !== account.chainId) {
            this.chainId$.next(account.chainId!);
          }
          this.n.success({ title: 'Neon wallet connected' });
        }
        if (account.isDisconnected) {
          this.address$.next(account.address!);
          if (this.chainId$.value !== environment.chainId) {
            this.chainId$.next(environment.chainId);
          }
          this.n.success({ title: 'Neon wallet disconnected' });
        }
        account.connector?.getProvider().then(this.setProvider);
      }
    });

    watchChainId(this.wagmiConfig, {
      onChange: network => {
        const account = getAccount(this.wagmiConfig);
        account.connector?.getProvider().then(this.setProvider);
      }
    });

    this.subs.push(this.address$.pipe(switchMap(address => address?.length ?
      this.walletBalance(<Address>address) : this.walletBalanceClean())).subscribe());
    this.subs.push(this.refresh$.pipe(filter(() => !!this.address), switchMap(() => {
      return this.walletBalance(<Address>this.address);
    })).subscribe());
  }

  refresh(): void {
    this.refresh$.next(true);
  }

  constructor(private n: NotificationService, private api: HttpRpcClient) {
    super();
  }
}
