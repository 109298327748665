import { Injectable } from '@angular/core';
import { BaseMessageSignerWalletAdapter, WalletNotReadyError } from '@solana/wallet-adapter-base';
import {
  Cluster,
  clusterApiUrl,
  Commitment,
  Connection,
  LAMPORTS_PER_SOL,
  PublicKey,
  Transaction,
  TransactionInstruction
} from '@solana/web3.js';
import {
  BehaviorSubject,
  combineLatest,
  delay,
  distinctUntilChanged,
  filter,
  from,
  map,
  Observable,
  ReplaySubject,
  skip,
  Subject,
  SubscriptionLike,
  switchMap,
  tap
} from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { Big } from 'big.js';
import { environment } from '../../environments/environment';
import { itemUnsubscribe, simulateTransaction } from '../../utils';
import { NotificationService } from '../../notifications';
import { SolanaWallet, TransactionSimulateResponse, WindowProvider } from '../../models';

@Injectable()
export class SolanaWalletService {
  balance$: BehaviorSubject<Big> = new BehaviorSubject<Big>(new Big(0));
  refresh$: Subject<boolean> = new Subject<boolean>();
  connected$ = new BehaviorSubject<boolean>(false);
  connectionError$ = new BehaviorSubject<string>('');
  windowProviders: WindowProvider = {} as WindowProvider;
  loading$ = new BehaviorSubject(false);
  hasError$ = new BehaviorSubject(false);
  private _connection: Connection;
  private _connection$: ReplaySubject<Connection> = new ReplaySubject<Connection>(0);
  private _provider: BaseMessageSignerWalletAdapter;
  private _publicKey: PublicKey;
  private _publicKey$: ReplaySubject<PublicKey> = new ReplaySubject<PublicKey>(0);
  private _cluster: Cluster = environment.network as Cluster;
  private _solanaUrl: string = environment.urls.solana;
  private subs: SubscriptionLike[] = [];

  get url(): string {
    try {
      return clusterApiUrl(this._cluster);
    } catch (e) {
      return this._solanaUrl;
    }
  }

  get provider(): BaseMessageSignerWalletAdapter {
    return this._provider;
  }

  get connection(): Connection {
    return this._connection;
  }

  get publicKey(): PublicKey {
    return this._publicKey;
  }

  set publicKey(pubkey: PublicKey) {
    this._publicKey = pubkey;
    this._publicKey$.next(pubkey);
  }

  get publicKey$(): Observable<PublicKey> {
    return this.connected$.pipe(filter(d => d), switchMap(() => this._publicKey$));
  }

  get pubkey$(): Observable<string> {
    return this.publicKey$.pipe(map(d => {
      if (d) {
        const pubkey = d.toBase58();
        return `${pubkey.slice(0, 5)}..${pubkey.slice(-5)}`;
      }
      return '';
    }));
  }

  // This is hack to detect account changes
  // There is no such methods in wallet adapters
  private detectProvider(): void {
    if ('solana' in window) {
      const anyWindow: any = window;
      if (anyWindow.solana.isPhantom) {
        this.windowProviders['phantom'] = anyWindow.solana;
        console.log('Phantom wallet found!');
      }
      if (anyWindow.backpack) {
        this.windowProviders['backpack'] = anyWindow.backpack;
        console.log('Backpack wallet found!');
      }
      if (anyWindow.solflare) {
        //Doesn't support accountChanged event
        this.windowProviders['solflare'] = anyWindow.solflare;
        console.log('Solflare wallet found!');
      }
    } else {
      console.error('No wallets was found!');
    }
  }

  private subscribeToAccountChanges(): void {
    for (const prop in this.windowProviders) {
      this.windowProviders[prop as keyof WindowProvider].on('accountChanged', async (publicKey: any) => {
        if (publicKey) {
          console.log('Account changed to:', publicKey.toString());
          await this._provider.disconnect();
          this.walletConnect(this._provider);
        } else {
          console.log('Account disconnected');
        }
      });
    }
  }

  walletBalance(pubkey: PublicKey): Observable<Big> {
    return from(this.connection.getBalance(pubkey)).pipe(map(b => {
      const balance = new Big(b);
      this.balance$.next(balance);
      return balance;
    }));
  }

  walletBalanceClean(): Observable<Big> {
    this.balance$.next(new Big(0));
    return this.balance$.pipe(delay(100));
  }

  transactionSimulate = (data: TransactionInstruction[], commitment: Commitment = 'singleGossip'): Observable<TransactionSimulateResponse> => {
    const transaction = new Transaction();
    transaction.feePayer = this.publicKey;
    transaction.add(...data);
    return this._connection$.pipe(
      switchMap(c => from(simulateTransaction(c, transaction, commitment))),
      map(simulate => ({ transaction, simulate })));
  };

  checkMinimumBalanceForRentExpression(instructions: TransactionInstruction[], currentRent = 0): Observable<number> {
    const dataLength = instructions.reduce((p, c) => p + c.data.length, 0);
    return combineLatest([this._connection$, this.walletBalance(this.publicKey)]).pipe(switchMap(([c, b]) => {
      return from(c.getMinimumBalanceForRentExemption(dataLength)).pipe(map((accountRentExempt) => { // 1385040 Lamport
        const rent = accountRentExempt + currentRent;
        if (b.lt(rent)) {
          throw new Error(`You don't have enough SOL for this transaction. Needs minimum balance ${rent / LAMPORTS_PER_SOL} SOL`);
        }
        return rent;
      }));
    }));
  }

  walletConnect(provider: SolanaWallet): void {
    this._provider = provider as BaseMessageSignerWalletAdapter;
    if (this._provider) {
      this._provider.once('connect', this.connectHandler);
      this._provider.once('disconnect', this.disconnectHandler);
      // @ts-ignore
      this._provider.once('accountChanged', this.accountChangedHandler);
    }
    this.connect();
  }

  init(): void {
    this._connection = new Connection(this.url, 'confirmed');
    this._connection$.next(this._connection);
    this.subs.push(this.publicKey$.pipe(switchMap(address => address instanceof PublicKey ?
      this.walletBalance(address) : this.walletBalanceClean())).subscribe());
    this.subs.push(this.refresh$.pipe(filter(() => !!this._publicKey),
      throttleTime(100), switchMap(() => this.walletBalance(this._publicKey))).subscribe());
    this.subs.push(this.connected$.pipe(distinctUntilChanged(), skip(1), tap(d => {
      if (d) {
        this.n.success({ title: 'Solana wallet connected' });
      } else {
        this.n.success({ title: 'Solana wallet disconnected' });
      }
    })).subscribe());
  }

  destroy(): void {
    this.disconnect();
    itemUnsubscribe(this.subs);
  }

  connect(): void {
    this.loading$.next(true);
    if (this._provider) {
      this._provider.connect().catch(e => {
        if (e instanceof WalletNotReadyError) {
          const message = `Please install <a href='${this._provider.url}' target='_blank'>${this._provider.name}</a>`;
          this.n.error({ title: `${this._provider.name} wallet isn't connected`, message });
        } else {
          const message = e?.message ?? '';
          this.n.error({ title: `${this._provider.name} wallet isn't connected`, message });
        }
        this.loading$.next(false);
        this.hasError$.next(true);
      });
    } else {
      this.n.error({ title: `The wallet isn't initialised` });
      this.loading$.next(false);
      this.hasError$.next(true);
    }
  }

  disconnect(): void {
    if (this._provider) {
      this._provider.disconnect()
        .catch(e => {
          const message = e?.message ?? '';
          this.n.error({ title: 'Solana wallet not disconnected', message });
        });
    } else {
      throw new Error(`Solana wallet isn't initialized...`);
    }
  }

  refresh(): void {
    this.refresh$.next(true);
  }

  private connectHandler = (publicKey: PublicKey) => {
    this.publicKey = publicKey;
    this.connected$.next(true);
  };

  private disconnectHandler = () => {
    //@ts-ignore
    this.publicKey = null;
    this.connected$.next(false);
  };

  private accountChangedHandler = (publicKey: PublicKey) => {
    if (publicKey) {
      this.publicKey = publicKey;
    } else {
      this.connect();
    }
    this.n.success({ title: 'Solana wallet changed' });
  };

  constructor(private n: NotificationService) {
    this.detectProvider();
    Object.keys(this.windowProviders).length && this.subscribeToAccountChanges();
  }
}
